.thumb {

	align-self: center;
	object-fit: cover;

	/** Dimensions align with `FileIcon` rendered when no thumb's available ! */
	height: 6.375rem;
	width: 5.5rem;

}
