$IMAGE_HEIGHT: 7.5rem;

.card {
	min-height: $IMAGE_HEIGHT;
}

.logo {
	height: 100%;
	object-fit: contain;
}

.logoContainer {
	height: $IMAGE_HEIGHT;
	padding: 0 1.5rem;
}
