:root {
	/* color palette */
	--green: #299c39;
	--red: #ef4444;
	--yellow: #facc15;
	--orange: #f97316;
	--blue: #3b82f6;
	--gray: #e5e5e5;
	--light-gray: #f5f5f5;
	--dark-gray: #898989;

	--success: var(--green);
	--error: var(--red);
	--info: var(--blue);

	/* theme */
	--brand-color: var(--accent-color, var(--green));

	/* base vars */
	--base-border-radius: 0.25rem;
	--base-container-border: 0.1rem solid rgba(0, 0, 0, 0.12);

	--table-row-even-bg-color: #ffffff;
	--table-row-odd-bg-color: #fdfdfd;

	/* shared vars only */
	--focus-visible-transition: none;
	--focus-visible-outline: 0.2rem solid orange;
	--focus-visible-z-index: 1;

	--input-border: 0.1rem solid var(--dark-gray);
	--input-border-radius: var(--base-border-radius);
	--input-padding: 1rem;

	--input-error-border-color: var(--red);
	--input-error-border-width: 0.15rem;
	--input-error-hover-border-color: #d41212;

	--button-default-disabled-color: rgba(0, 0, 0, 0.26);
	--button-default-disabled-border-color: #e8e8e8;

	--button-primary-disabled-background-color: rgba(41, 156, 57, 0.25);
	--button-secondary-disabled-background-color: rgba(249, 115, 22, 0.25);
	--button-warning-disabled-background-color: rgba(250, 204, 21, 0.25);
	--button-danger-disabled-background-color: rgba(239, 68, 68, 0.25);
	--button-dangerOutline-disabled-background-color: rgba(255, 255, 255, 0.25);
	--button-info-disabled-background-color: rgba(59, 130, 246, 0.25);

	--typography-p-font-size: 1.27272727273rem;
	--typography-h2-font-size: 1.909090909rem;
}

a {
	text-decoration: none;
	color: var(--brand-color);

	&:hover {
		text-decoration: underline;
	}

	&:focus-visible {
		transition: var(--focus-visible-transition);
		outline: var(--focus-visible-outline);
		z-index: var(--focus-visible-z-index);
	}
}