@import "index.scss";

.root {
	@extend .noPrint;

	z-index: 150;
}

.button {
	border-radius: 0;
	cursor: pointer !important;
	text-transform: none !important;
}

.menuItemLink {
	color: inherit !important;

	&:hover {
		text-decoration: none !important;
	}
}