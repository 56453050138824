@import "ComponentsE/styling/Theme.module.scss";

:root {

	--accent-color-admin: #c56000;

	--app-bar-control-background: rgba(255, 255, 255, 0.2);
	--app-bar-height: 5.6rem;

	--header-panel-color: #d1ffd1;

}

* {
	border: 0;
	box-sizing: border-box;
	font-family: inherit;
	font-size: 100%;
	margin: 0;
	max-width: 100%;
	outline: 0;
	padding: 0;
	vertical-align: baseline;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;

	&::selection {
		background: rgba(41, 156, 57, 0.15);
	}

	&.fit-content-width {
		width: -moz-fit-content !important;
		width: fit-content !important;
	}

	&.oneline,
	&.oneline * {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

body {
	min-height: 100%;
	min-width: 100%;
	overflow-y: scroll;
}

html {
	font-family: Arial, Roboto, sans-serif, serif;
	font-size: 62.5%;
	font-weight: normal;
	min-height: 100%;
	min-width: 100%;
}

code,
pre {
	font-family: monospace;
}

@media (orientation: landscape) {
	html {
		-webkit-text-size-adjust: 100%;
	}
}

.MuiAutocomplete-popper,
.MuiPopover-root {
	z-index: 15000 !important;
}

.MuiAutocomplete-paper,
.MuiInputBase-root {
	border-radius: 0 !important;
}

.MuiDialog-root {
	z-index: 10000 !important;
}

.MuiIconButton-root {
	flex-shrink: 0;
}

.MuiTooltip-popper {
	z-index: 20000 !important;
}

.MuiTooltip-tooltip {
	font-size: 1.2rem !important;
	text-align: center;
}

[class^="SnackbarItem"] {
	font-size: 1.4rem !important;
	white-space: pre;
}

.showOnPrint {
	display: none !important;
}

@media print {

	table tr {
		page-break-inside: avoid;
	}

	.showOnPrint {
		display: block !important;
	}

	.noPrint {
		display: none !important;
		min-height: 0;
		height: 0;

		* {
			display: none !important;
			min-height: 0;
			height: 0;
		}
	}

	::-webkit-scrollbar {
		display: none;
	}
}