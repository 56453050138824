$DROP_TRANSITION: 0.2s ease-out;

.drop {
	background: var(--paper-color);
	cursor: default;
	overflow: hidden;
	position: relative;
	transition: filter $DROP_TRANSITION;

	:global(> *) {
		transition: transform $DROP_TRANSITION;
	}

	&:not(:global(.dropInert)) {
		border: 0.2rem dashed var(--accent-color);
		min-height: 21rem;
	}
}

.dropOver {
	filter: drop-shadow(0 0 1rem var(--accent-color));

	&:not(:global(.dropInert)):global(> *) {
		transform: scale(1.1);
	}
}
