.adminAuthed {
	background: var(--accent-color-admin) !important;
}

.iconButton {

	$UIBAR_ICON_DIMS_THRESHOLD: 420px;

	@media all and (max-width: $UIBAR_ICON_DIMS_THRESHOLD) {
		height: var(--uibar-icon-button-dims) !important;
		width: var(--uibar-icon-button-dims) !important;
	}

	@media all and (max-width: $UIBAR_ICON_DIMS_THRESHOLD) {
		--uibar-icon-button-dims: 4rem;
	}

	@media all and (max-width: 385px) {
		--uibar-icon-button-dims: 3.6rem;
	}

	@media all and (max-width: 365px) {
		--uibar-icon-button-dims: 3.2rem;
	}

	@media all and (max-width: 345px) {
		--uibar-icon-button-dims: 2.8rem;
	}

	@media all and (max-width: 315px) {
		--uibar-icon-button-dims: 2.4rem;
	}

}

.iconButtonImage {

	:global(img) {
		transition: transform 0.4s ease-out;
	}

	&:hover, &:focus, &:focus-within {
		background-color: initial !important;

		:global(img) {
			transform: scale(1.2);
		}
	}

	&::after {
		content: "";
		background: #fff;
		border-radius: 50%;
		display: block;
		position: absolute;
		height: 105%;
		width: 105%;
		z-index: -1;
	}

}

.uiBar {

	color: #fff !important;

	@media print {
		display: none !important;
	}

}
