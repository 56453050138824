.root {
	
	.container {

		align-items: center;
		display: flex;
		gap: 1.5rem;
		
		/** Thumbnail */
		>:first-child {
			width: 3rem;
		}

		a {
			color: var(--accent-color);
			font-size: 1.4rem;
			text-decoration: none;

			&:hover, &:focus {
				text-decoration: underline;
			}
		}

	}

	&.clickable {
		cursor: pointer;
	}

}
