.background {
	background: rgba(0,0,0,0.8) !important;
	border-width: 0.375rem !important;
	
	*:not(a) {
		color: #eee !important;
	}

	a {
		color: inherit;
		text-decoration: underline;
	}
}

.content {
	font-family: "Comic Sans MS", "Comic Sans", "Comic Neue", Arial, Roboto, sans-serif, serif !important;
}
