.paper {
	position: fixed;
	top: calc(5.6rem + 1.5rem); // app bar height
	transform: scaleY(0);
	transform-origin: top;
	transition: transform 0.2s ease-out !important;
	right: 1.5rem;
	z-index: 1000;

	@media all and (max-width: 640px) {
		width: auto;
		margin: 0 1.5rem;
		right: 0;
		left: 0;
	}

	@media all and (min-width: 640px) and (max-width: 960px) {
		left: 1.5rem;
		right: unset;
		width: 34rem;
	}
}

.paperVisible {
	transform: scaleY(1);
}