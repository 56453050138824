.adminAuth {
	background: var(--accent-color-admin);
	color: #fff;

	>:first-child {
		color: #eee;
	}
}

.authDetails {
	color: var(--typography-color);
	text-align: center;

	&:global(.admin) {
		background: var(--accent-color-admin);
		color: #fff;
	}
	
	:global(.user-name) {
		font-weight: bold;
	}
}

.drawer {
	overflow-y: scroll !important;
}
