.container {
	align-items: flex-end;
	box-sizing: content-box;	// include padding in width when calculating when to remove windows on resize
	position: fixed;
	bottom: 0;
	padding: 0 1.5rem;
	right: 0;
	z-index: 1000;

	@media all and (max-width: 640px) {
		box-sizing: border-box;
		width: 100%;
	}

	@media print {
		display: none !important;
	}
}
