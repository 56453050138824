.checkbox {
	position: absolute !important;
	top: 0.5rem !important;
	left: 0.5rem !important;
}

.menuButton {
	position: absolute !important;
	top: 0.5rem !important;
	right: 0.5rem !important;
}

.tile {
	position: relative;
	height: 15rem;
	width: 15rem;

	&:focus, &:hover {
		background: var(--base-color) !important;
	}
}
