.header {
	background: var(--paper-color);
	position: sticky;
	top: 0;
	z-index: 10;
}

.paper {
	max-height: calc(100vh - 5.6rem - 1.5rem - 1.5rem - 6rem); // offset app bar height + bottom margin
	max-width: calc(100vw - 1.5rem - 1.5rem);
	width: 30rem;
	overflow-y: auto;

	@media print {
		display: none !important;
	}
}