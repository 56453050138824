.mainContainer {
	@media print {
		grid-template-columns: 1fr !important;
	}
}

.mainContainerInner {
	@media print {
		margin-top: 0 !important;
		padding: 0 !important;
	}
}

.side {
	@media print {
		display: none !important;
	}
}
