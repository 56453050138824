.ExtraLink {
	margin-left: auto !important;
}

.Indicator {
	transition: none !important;
}

.Select {

	background: var(--base-color);
	text-align: center !important;

	:global(.MuiInputBase-root),
	:global(.MuiSelect-root) {

		/**
		 * Matching the Select height to the Tabs height
		 *
		 * Ensures consistent positioning for e.g. stickiness.
		 */
		height: 3.475rem !important;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		text-align: center !important;

	}

}

.Tab {
	$TAB_PADDING_HORIZONTAL: 1.5rem;
	font-size: 1.3rem !important;
	min-height: 3rem !important;
	min-width: 6rem !important;
	padding-left: $TAB_PADDING_HORIZONTAL !important;
	padding-right: $TAB_PADDING_HORIZONTAL !important;
	text-transform: none !important;

	&:hover {
		text-decoration: none;
	}
}

.Tabs {

	background: var(--base-color);
	min-height: 3rem !important;

	:global(.MuiTabs-flexContainer) {
		align-items: center;
	}

	@media print {
		display: none !important;
	}

}