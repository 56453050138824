.Container {
	display: grid !important;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
}

.Indicator {
	transition: none !important;
}

.Tab {
	$TAB_PADDING_HORIZONTAL: 0.5rem;
	font-size: 1.1rem !important;
	min-height: 3rem !important;
	min-width: 6rem !important;
	padding-left: $TAB_PADDING_HORIZONTAL !important;
	padding-right: $TAB_PADDING_HORIZONTAL !important;
}

.Tabs {
	background: var(--paper-color);
	margin-bottom: -0.75rem;
	min-height: 3rem !important;
	padding-bottom: 0.75rem;
	position: sticky;
	top: -1.5rem;
	z-index: 100;
}
