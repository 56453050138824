.UiBarSearchbar {
	background: var(--accent-color);
	z-index: 10;
	$UIBARSEARCHBAR_ICON_HEIGHT: 4.5rem;
	$UIBARSEARCHBAR_ICON_OFFSET: 6.75rem;
	$UIBARSEARCHBAR_ICON_OFFSET_X: 0.75rem;
	$UIBARSEARCHBAR_ICON_OFFSET_Y: 0.45rem;

	:global(.MuiFormControl-root) {
		background: var(--app-bar-control-background) !important;
	}

	* {
		background: none !important;
	}
	
	input::placeholder {
		opacity: 0.8;
	}

	input::selection {
		background: #fff;
		color: var(--accent-color);
	}

	@media all and (max-width: 640px) {
		height: $UIBARSEARCHBAR_ICON_HEIGHT !important;
		opacity: 1;
		position: fixed;
		right: $UIBARSEARCHBAR_ICON_OFFSET_X;
		top: $UIBARSEARCHBAR_ICON_OFFSET_Y;
		width: $UIBARSEARCHBAR_ICON_HEIGHT;

		&::before {
			content: "";
			display: block;
			height: 5.6rem;
			right: -$UIBARSEARCHBAR_ICON_OFFSET_X;
			position: absolute;
			top: -$UIBARSEARCHBAR_ICON_OFFSET_Y;
			width: $UIBARSEARCHBAR_ICON_OFFSET;
		}

		input {
			min-width: 0 !important;
		}

		svg {
			right: 0.5rem !important;
		}

		&:focus, &:focus-within {
			width: calc(100% - #{$UIBARSEARCHBAR_ICON_OFFSET});

			@media all and (max-width: 385px) {
				width: calc(100% - #{$UIBARSEARCHBAR_ICON_OFFSET} + 1rem);
			}
			@media all and (max-width: 345px) {
				width: calc(100% - #{$UIBARSEARCHBAR_ICON_OFFSET} + 1.5rem);
			}
		}

		&:not(:focus):not(:focus-within) {
			input {
				min-width: 0 !important;
				opacity: 0 !important;
			}
		}
	}
}

.UiBarSearchbarAdmin {
	background: var(--accent-color-admin);
}

.UiBarSearchbarContainer {
	max-width: 64rem;
	justify-self: center;
	width: 100%;
}

.UiBarSearchbarOption {
	&:last-child {
		[class*="PickerResult"]::after {
			display: none !important;
		}
	}
}

.UiBarSearchbarPopper {
	top: -0.6rem !important;
}

.UiBarSearchbarPopperListbox {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
