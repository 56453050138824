.link {
	cursor: pointer;

	&:hover, &:focus {
		text-decoration: underline !important;
	}
}

.disabled {
	cursor: default !important;

	&:hover, &:focus {
		text-decoration: none !important;
	}
}

.disabledFilter {
	filter: grayscale(1);
}
