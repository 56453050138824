@import "index.scss";

.root {
	@extend .noPrint;

	$department_menu_height: 7.6rem;

	background: var(--header-panel-color) !important;
	color: green;
	min-height: $department_menu_height;

}

.container {

	$department_menu_padding: 0.75rem;

	padding: 0 $department_menu_padding;
	padding-top: $department_menu_padding;

}