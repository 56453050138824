@mixin UiBarControl {
	border-color: rgba(255,255,255,0.5) !important;
	border-width: 0.1rem !important;
	color: white !important;
	font-weight: bold !important;
	position: relative;
	transition: none !important;

	&:hover, &:hover *,
	&:focus, &:focus *,
	&:focus-within, &:focus-within * {
		border-color: white !important;
	}
}

.UiBarControl {
	@include UiBarControl;
	* {
		@include UiBarControl;
	}
}
