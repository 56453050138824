.root {
	bottom: 0;
	left: 50%;
	position: fixed;
	transform: translateX(-50%);
	z-index: 1000000000;
}

.layoutPlaceholderIcon {
	visibility: hidden;
}
