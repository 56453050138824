.alert {

	:global(.MuiAlert-action), :global(.MuiAlert-icon) {
		flex-shrink: 0;
	}
	:global(.MuiAlert-message) {
		width: 100%;
	}

	@media print {
		display: none !important;
	}

}

.dense {
	:global(.MuiAlert-message) {
		padding: 0.15rem 0 !important;
	}
}
