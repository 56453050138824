.PickerOptionComponent {
	position: relative;
	width: 100%;

	&::after {
		background: rgba(0,0,0,0.1);
		bottom: -0.6rem;
		content: "";
		display: block;
		height: 0.1rem;
		left: 0;
		position: absolute;
		width: 100%;
	}
}
