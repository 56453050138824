@import "index.scss";

.root {
	@extend .noPrint;

	min-height: 23.5rem;
}

.rosterMenu_date {
	display: block;
	font-size: 1.2em;
	color: #a3a3a3;
	font-weight: bold;
}

.rosterPeriodSelector {

	display: grid;
	gap: 1.5rem;
	grid-template-columns: 1fr max-content 1fr;
	align-items: center;

	.nextLink {
		grid-column: 3;
		justify-self: flex-end;
		text-align: right;
	}

	.selectContainer {

		flex-shrink: 0;
		grid-column: 2;

		select {

			$rp_selector_py: 0.8rem;

			font-size: 1.25rem;
			padding-top: $rp_selector_py;
			padding-bottom: $rp_selector_py;

			.selectedOption {
				background: yellow;
			}

			.currentOptions {
				background: lightgray;
			}

		}

		@media all and (max-width: 640px) {

			.label {
				display: none;
			}

			select {
				width: 100%;
			}

		}

	}

}