.composer {
	background: var(--paper-color);
	bottom: 0;
	position: sticky;
	z-index: 5;
}

.pane {
	box-sizing: border-box;		// ChatWindowHost sets to `content-box`
	display: grid;
	grid-template-rows: max-content 1fr;
	overflow-y: hidden;

	@media all and (max-width: 640px) {
		width: 100%;
	}
}

.paneClosed {
	height: 6rem;
	max-height: 6rem;
}

.paneOpen {
	height: 42rem;
	max-height: calc(100vh - 5.6rem - 6rem - 3rem);
}

.send {
	height: 3rem;
	width: 3rem;
}

.messageContainer {
	overflow-y: scroll;
	padding-right: 1.5rem;
}

.userPickerContainer {
	margin-top: auto;
	margin-bottom: 0.75rem;
	z-index: 15;
} 
